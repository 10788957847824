import Header from "./components/Header";
import Home from "./views/Home";
import { Routes, Route } from 'react-router-dom'
import Proposal from './views/Proposal';
import { useEffect } from "react";
import { isWalletConnected } from "./Blockchain.services";



const App = () => {
  useEffect(async () => {
    await isWalletConnected()
  }, [])

  return (
    <div className="min-h-screen bg-white text-gray-900 dark:bg-[#212936] dark:text-gray-300">
      <Header/>
      <Routes>
          <Route path="/" element={<Home />} />
          
          <Route path="/proposal/:id" element={<Proposal />} />
      </Routes>

    </div>
  )
}

export default App;
