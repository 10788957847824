import ProposalDetails from '../components/ProposalDetails';
import Voters from '../components/Voters';

const Proposal = () => {
    return (
        <>
        <ProposalDetails />
        <Voters />
        </>
    )
} 

export default Proposal