import Banner from '../components/Banner';
import Proposals from '../components/Proposals';

const Home = () => {
    return (
        <>
        <Banner />
        <Proposals />
        </>
    )
}

export default Home